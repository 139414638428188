@fontFamily: CrimsonText-Italic;

.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  margin-top: 50px;
  padding: 30px 0;

  > h2 {
    text-align: center;
    font-size: 32px;
    font-family: @fontFamily;
    color: var(--app-text-color);
    line-height: 45px;
  }

  .container {
    > .bigImgItem {
      width: 100%;
      object-fit: contain;
      height: auto;
    }

    > .descList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      text-align: center;
      h3 {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    padding: 0;

    // >h2 {
    //   font-size: 24px;
    // }
  }
}

